const backend_uri = process.env.REACT_APP_BACKEND_URL;

export const getLeaderboard = async (token) => {
    const response = await fetch(`${backend_uri}/api/v1/leaderboard`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch leaderboard information');
    }

    const users = await response.json();

    // Массив логинов для 'foundation #1'
    const foundation1Logins = [
        'yedochsh',
        'malpamys',
        'myertaye',
        'mboranba',
        'azhalgas',
        'akhabdul',
        'dmussina',
        'aaryskha',
        'aakash',
        'nkabulov',
        'gmeirimk',
        'aaitkozh',
        'ukabdoll',
        'bsagat',
        'mduisen',
        'yeakhmet',
        'ebairamo',
        'cooldown',
        'ilim',
        'maissaye',
        'aideyato',
        'amarden',
        'basagitz',
        'andulmaev',
        'dibadull',
        'msuyundy',
        'akakimbe',
        'rkasseno',
        'onurseit',
        'torazali',
        'arakym',
        'mtulebay',
        'syakupov',
        'ysyzdyko',
        'maabylka',
        'ttest',
        'akairamb',
        'akydyrba',
        'ssaidaly',
        'tpugashb',
        'eaktaev',
        'brakhimb',
        'mrakhimo',
        'nsheri',
        'ebukemba',
        'zzhaksyb',
        'aashirku',
        'nbeisenb',
        'mkudaibe',
        'yealdaber',
        'dabektur',
        'byessirk',
        'dakussai',
        'ydoskeno',
        'lalpieva',
        'tbaitleu',
        'bayelmurz',
        'onurmukh',
        'ajarzhan',
        'ashpring',
        'igussak',
        'mukairbe',
        'tkoszhan',
        'kamamedov',
        'dausetov',
        'tgrigory',
        'abeiseke',
        'nmedeube',
        'sabupher',
        'bshaimar',
        'iabijano',
        'tiorazov',
        'ykozhan',
        'romirkul',
        'nkali',
        'bnurlyba',
        'asakhant',
        'arabayev',
        'raidarkh',
        'aasset',
        'dassanov',
        'nnurgali',
        'bgabdyzh',
        'nskakova',
        'aomarbek',
        'ykalkin',
        'ikenes',
        'atoleu',
        'stursunb',
        'missayev',
    ]

    // Обновляем поле 'cohort' для пользователей из 'foundation1Logins'
    users.forEach((user) => {
        if (foundation1Logins.includes(user.login)) {
            user.cohort = 'Foundation #1';
        }else {
            user.cohort = 'Foundation #2';
        }
    });

    return users;
};
