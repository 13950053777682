const backend_uri = process.env.REACT_APP_BACKEND_URL

// src/api/user.js
export const getPersonalInfoAndHours = async (token) => {
    const response = await fetch(`${backend_uri}/api/v1/user/me`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        const error = new Error('Failed to fetch user info and hours');
        error.status = response.status;
        throw error;
    }

    return await response.json();
};

  