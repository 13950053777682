import React, { createContext, useState, useEffect } from 'react';
export const AuthContext = createContext();

const backend_uri = process.env.REACT_APP_BACKEND_URL

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: localStorage.getItem('access_token') || null,
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get('code');

    if (authCode) {
      fetchToken(authCode).then((token) => {
        if (token) { // Проверка наличия токена перед сохранением
          localStorage.setItem('access_token', token);
          setAuthState({ token });
          window.location.href = '/';
        }
      });
    }
  }, []);


  const fetchToken = async (code) => {
    try {
      const response = await fetch(`${backend_uri}/api/auth?code=${code}`);
      const data = await response.json();

      // Проверка на существование access_token в ответе
      if (data && data.access_token) {
        return data.access_token;
      } else {
        console.error('Token not found in response:', data);
        return null;
      }
    } catch (error) {
      console.error('Error fetching token:', error);
      return null;
    }
  };



  const logout = () => {
    localStorage.removeItem('access_token');
    setAuthState({ token: null});
    window.location.href = '/';
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
