import React, { useContext, useEffect, useState } from 'react';
import Navbar from './NavBar';
import Sidebar from './SideBar';
import Leaderboard from './Leaderboard';
import { AuthContext } from '../context/AuthContext';
import { getPersonalInfoAndHours } from '../api/user';
import './Dashboard.css';

const Dashboard = () => {
    const { authState, logout } = useContext(AuthContext);
    const { token } = authState;

    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getPersonalInfoAndHours(token);
                setUserInfo(data);
            } catch (error) {
                console.error('Error fetching user info:', error);
                if (error.status === 401) {
                    logout();
                } else {
                    setUserInfo(null);
                }
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            fetchData();
        }
    }, [token, logout]);

    if (loading) return <div>Loading...</div>;
    if (!userInfo) return <div>No user info</div>;

    return (
        <div className="App">
            <Navbar user={userInfo} logout={logout} />

            <div className="main-content">
                <div className="content-container">
                    <Sidebar user={userInfo} />
                    <Leaderboard />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
