import React, { useState, useEffect, useContext } from 'react';
import './Leaderboard.css';
import { getLeaderboard } from '../api/leaderboard';
import { AuthContext } from '../context/AuthContext';

function Leaderboard() {
  const [data, setData] = useState([]);
  const [filterCohort, setFilterCohort] = useState('All');
  const { authState } = useContext(AuthContext);
  const { token } = authState;

  useEffect(() => {
    if (token) {
      fetchLeaderboardData(token);
    }
  }, [token]);

  const fetchLeaderboardData = async (token) => {
    try {
      const leaderboardData = await getLeaderboard(token);
      const sortedData = leaderboardData.sort((a, b) => b.total_xp - a.total_xp);
      setData(sortedData);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    }
  };

  const filteredData = data.filter((user) => {
    if (filterCohort === 'All') {
      return true;
    } else {
      return user.cohort === filterCohort;
    }
  });

  return (
      <div className="leaderboard">
        <div className="leaderboard-header">
          <h2>Leaderboard</h2>
        </div>
        <br/>
        <div className="filters">
          <select
              value={filterCohort}
              onChange={(e) => setFilterCohort(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Foundation #1">Foundation #1</option>
            <option value="Foundation #2">Foundation #2</option>


          </select>
        </div>

        <div className="leaderboard-content">
        <div className="leaderboard-row">
            <div className="leaderboard-cell">Rank</div>
            <div className="leaderboard-cell">Login</div>
            <div className="leaderboard-cell">Cohort</div>
            <div className="leaderboard-cell">XP</div>
            <div className="leaderboard-cell">Role</div>
          </div>
          {filteredData.map((user, index) => (
              <div className="leaderboard-row" key={user.login}>
                <div className="leaderboard-cell" data-label="Rank">{index + 1}</div>
                <div className="leaderboard-cell" data-label="Login">{user.login}</div>
                <div className="leaderboard-cell" data-label="Cohort">{user.cohort}</div>
                <div className="leaderboard-cell" data-label="XP">{user.total_xp}</div>
                <div className="leaderboard-cell" data-label="Role">{user.role}</div>
              </div>
          ))}
        </div>
      </div>
  );
}

export default Leaderboard;
