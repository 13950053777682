import React from 'react';
import UserCard from './UserCard';
import Marketplace from './Marketplace';
import './Sidebar.css';

function Sidebar({ user }) {
  return (
    <div className="sidebar">
      <UserCard user={user} />
      {/*<Marketplace />*/}
    </div>
  );
}

export default Sidebar;
