import React from 'react';
import logo from '../assets/logo.svg';
import './Navbar.css';

function Navbar({ user, logout }) {
    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img src={logo} alt="Logo" className="logo" />
                <h1>Progress</h1>
            </div>

            <div className="navbar-buttons">
                <a
                    href="https://alemschool.atlassian.net/servicedesk"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button-style"
                >
                    Support
                </a>
                <button onClick={logout} className="button-style">Logout</button>
            </div>
        </nav>
    );
}

export default Navbar;
